import { forkJoin, of, BehaviorSubject, Observable, combineLatest } from 'rxjs';

import { mergeMap, catchError, finalize, tap, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { DetailedAnnotatedVariant } from 'app/model/entities/detailedAnnotatedVariant';
import {
  AnnotatedVariant,
  mapPaginatedDtoToVariantData,
  PaginatedReportSummaryData,
  PaginatedVariantData,
  RearrangementTypes,
  VariantMap,
  VariantName
} from 'app/model/entities/annotatedVariant';
import { VariantBrief } from 'app/model/entities/variantBrief';
import { VariantMetaData } from '../model/entities/variantMetaData';
import {
  clone as _clone,
  get as _get,
  has as _has,
  uniq as _uniq,
  values as _values
} from 'lodash';
import { AuthService } from 'app/services/auth.service';
import { HEADER_ETAG, HEADER_IF_NONE_MATCH, PMID_BASE_URL, CommonService } from './common.service';
import { PaginatedBiomarkerData, PertinentNegativeBiomarker } from 'app/model/entities/biomarker';
import { CaseService } from './case.service';
import { VariantAlias } from 'app/model/entities/variantAlias';
import { VariantCustomColumn } from 'app/model/entities/variantCustomColumn';
import {
  CaseLinkKeys,
  MAX_PAGINATION_SIZE,
  PaginatedCaseResponseKeys
} from 'app/model/valueObjects/caseLinksKeys';
import { TierNames } from 'app/model/valueObjects/ampTier';

interface ListToUpdateResult {
  listToUpdate: BehaviorSubject<AnnotatedVariant[]>;
  clonedList: AnnotatedVariant[];
  curVariantIndex: number;
  curVariant: AnnotatedVariant;
}

interface Revision {
  id: string;
  revision: number;
}

export const rearrangementVariantTypeMap: { [key in RearrangementTypes]: string } = {
  [RearrangementTypes.GENE_FUSION]: 'Gene Fusion',
  [RearrangementTypes.INTRAGENIC_DELETION]: 'Intragenic Deletion',
  [RearrangementTypes.INTRAGENIC_DUPLICATION]: 'Intragenic Duplication',
  [RearrangementTypes.INTRAGENIC_INVERSION]: 'Intragenic Inversion',
  [RearrangementTypes.FIVE_PRIME_PARTNERLESS_REARRANGEMENT]: "5' Partnerless Rearrangement",
  [RearrangementTypes.THREE_PRIME_PARTNERLESS_REARRANGEMENT]: "3' Partnerless Rearrangement",
  [RearrangementTypes.PARTIAL_GENE_DELETION]: 'Partial Gene Deletion',
  [RearrangementTypes.PARTIAL_GENE_DUPLICATION]: 'Partial Gene Duplication',
  [RearrangementTypes.SPLICE_VARIANT]: 'Splice variant'
};

@Injectable()
export class VariantService {
  private _detailedVariant: BehaviorSubject<DetailedAnnotatedVariant> =
    new BehaviorSubject<DetailedAnnotatedVariant>(null);
  private _detailedVariantError: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private _significantVariantList: BehaviorSubject<AnnotatedVariant[]> = new BehaviorSubject<
    AnnotatedVariant[]
  >([]);
  private _otherBiomarkerList: BehaviorSubject<AnnotatedVariant[]> = new BehaviorSubject<
    AnnotatedVariant[]
  >([]);

  private _paginatedOtherBiomarkerList: BehaviorSubject<AnnotatedVariant[]> = new BehaviorSubject<
    AnnotatedVariant[]
  >([]);

  private _unclassifiedVariantList: BehaviorSubject<AnnotatedVariant[]> = new BehaviorSubject<
    AnnotatedVariant[]
  >([]);
  private _containsUnprocessedVariants: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  private _unprocessedVariantList: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  private _filteredOutVariantList: BehaviorSubject<AnnotatedVariant[]> = new BehaviorSubject<
    AnnotatedVariant[]
  >([]);
  private _variantListError: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private _currentFilter: BehaviorSubject<any> = new BehaviorSubject<any>({});
  private _currentFilterError: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private _referenceList: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  private _biomarkersList: BehaviorSubject<PertinentNegativeBiomarker[]> = new BehaviorSubject<
    PertinentNegativeBiomarker[]
  >([]);
  private _variantMap: BehaviorSubject<VariantMap> = new BehaviorSubject<VariantMap>(null);
  private _variantAlias: BehaviorSubject<VariantAlias> = new BehaviorSubject<VariantAlias>(null);
  private _variantAliasError: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  private _loadingVariantAlias: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _loadingVariantList: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _loadingVariantDetails: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _loadingUnprocessedVariantsSettings: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private _updatingVariant: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _loadingCaseNote: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _updatingCaseNote: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private _caseNote: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private _caseNoteVersion: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  private _caseNoteError: BehaviorSubject<string> = new BehaviorSubject<string>('');

  private _customBriefErrors: BehaviorSubject<any> = new BehaviorSubject<any>({});
  private _updatingVariantBrief: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private _therapyExclusions: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  private _therapyExclusionsError: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private _revisionData: {
    variantFilter?: Revision;
  } = {};
  private _createOrEditCaseNoteLink: string;

  private _variantListEtag = '';
  private _variantListData;

  private _savingBulkEdit: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private bulkEditHref = '';
  private _variantBriefUpdated = new BehaviorSubject<AnnotatedVariant>(null);

  constructor(
    private _http: HttpClient,
    private authService: AuthService,
    private caseService: CaseService
  ) {}

  get loadingVariantAlias() {
    return this._loadingVariantAlias.asObservable();
  }

  get loadingUnprocessedVariantsSettings() {
    return this._loadingUnprocessedVariantsSettings.asObservable();
  }

  get loadingVariantList() {
    return this._loadingVariantList.asObservable();
  }

  get updatingVariant() {
    return this._updatingVariant.asObservable();
  }

  get loadingVariantDetails() {
    return this._loadingVariantDetails.asObservable();
  }

  get loadingCaseNote() {
    return this._loadingCaseNote.asObservable();
  }

  get updatingCaseNote() {
    return this._updatingCaseNote.asObservable();
  }

  get currentFilter() {
    return this._currentFilter.asObservable();
  }

  get currentFilterError() {
    return this._currentFilterError.asObservable();
  }

  get variantAlias() {
    return this._variantAlias.asObservable();
  }

  get detailedVariant() {
    return this._detailedVariant.asObservable();
  }

  get variantMap() {
    return this._variantMap.asObservable();
  }

  get detailedVariantError() {
    return this._detailedVariantError.asObservable();
  }

  get variantAliasError() {
    return this._variantAliasError.asObservable();
  }

  get variantListError() {
    return this._variantListError.asObservable();
  }

  get significantVariantList() {
    return this._significantVariantList.asObservable();
  }

  get otherBiomarkerList() {
    return this._otherBiomarkerList.asObservable();
  }

  get paginatedOtherBiomarkerList$() {
    return this._paginatedOtherBiomarkerList.asObservable();
  }

  get unclassifiedVariantList() {
    return this._unclassifiedVariantList.asObservable();
  }

  get unprocessedVariantList() {
    return this._unprocessedVariantList.asObservable();
  }

  get containsUnprocessedVariants() {
    return this._containsUnprocessedVariants.asObservable();
  }

  get unprocessedVariantSettingsUrl(): string {
    return this.caseService.getCurrentCaseLink(
      CaseLinkKeys.VIEW_CASE_UNPROCESSED_VARIANTS_SETTINGS
    );
  }

  get filteredOutVariantList() {
    return this._filteredOutVariantList.asObservable();
  }

  get referenceList() {
    return this._referenceList.asObservable();
  }

  get biomarkersList() {
    return this._biomarkersList.asObservable();
  }

  get caseNote() {
    return this._caseNote.asObservable();
  }

  get caseNoteVersion() {
    return this._caseNoteVersion.asObservable();
  }

  get caseNoteError() {
    return this._caseNoteError.asObservable();
  }

  get customBriefErrors() {
    return this._customBriefErrors.asObservable();
  }

  get updatingVariantBrief() {
    return this._updatingVariantBrief.asObservable();
  }

  get therapyExclusions() {
    return this._therapyExclusions.asObservable();
  }

  get therapyExclusionsError() {
    return this._therapyExclusionsError.asObservable();
  }

  get savingBulkEdit() {
    return this._savingBulkEdit.asObservable();
  }

  get variantBriefUpdated(): Observable<AnnotatedVariant> {
    return this._variantBriefUpdated.asObservable();
  }

  static getSignificantVariantNames(variantList: AnnotatedVariant[]): string[] {
    return <string[]>(
      _uniq(
        variantList.reduce((list, variant) => list.concat(variant.originalName.split(', ')), [])
      )
    );
  }

  getFilteredClinicalTrialVariantOptions(variants: AnnotatedVariant[]): AnnotatedVariant[] {
    const GENOMIC_LOH_UNDETERMINED = 'Genomic LOH Undetermined';

    return variants.filter((variant: AnnotatedVariant) => {
      const hrdPositiveRegex = /^HRD.*Positive$/i;
      return (
        (variant.current.report && hrdPositiveRegex.test(variant.originalName)) ||
        (variant.originalName !== GENOMIC_LOH_UNDETERMINED && !variant.originalName.includes('HRD'))
      );
    });
  }

  loadVariantAlias(caseId: string, variantId: string) {
    this._loadingVariantAlias.next(true);
    this._variantAliasError.next(false);
    this._variantAlias.next(null);
    return this._http
      .get(this.authService.getURL('viewVariantAlias', { caseId, variantId }))
      .pipe(finalize(() => this._loadingVariantAlias.next(false)))
      .subscribe({
        next: (json: any) => {
          this._variantAlias.next(json);
          this._variantAliasError.next(false);
        },
        error: (error) => this._variantAliasError.next(true)
      });
  }

  loadVariantDetails(caseId: string, variantId: string) {
    this._loadingVariantDetails.next(true);
    this._detailedVariantError.next('');
    this._customBriefErrors.next({});
    this._therapyExclusionsError.next(false);
    this._detailedVariant.next(null);

    this.caseService.loadDiagnosis().subscribe();
    this._http
      .get(this.authService.getURL('viewVariantSummary', { caseId, variantId }))
      .pipe(
        mergeMap((json) =>
          this.caseService.renameDiagnosis(json, 'analytics', ['commonDisease', 'currentDisease'])
        ),
        finalize(() => this._loadingVariantDetails.next(false))
      )
      .subscribe({
        next: (json: any) => {
          const detailedVariant = new DetailedAnnotatedVariant(json);
          this._detailedVariant.next(detailedVariant);
          this._therapyExclusions.next(
            (detailedVariant.metadata && detailedVariant.metadata.therapyExclusions) || []
          );
        },
        error: () =>
          this._detailedVariantError.next(`Failed to load variant ${variantId} from case ${caseId}`)
      });
  }

  clearVariantDetails(): void {
    this._detailedVariant.next(null);
  }

  updateVariant(updatedVariant: AnnotatedVariant, overrideBody: any, diagnosisId: string) {
    this._updatingVariant.next(true);
    overrideBody['diagnosisId'] = diagnosisId;
    return this._http
      .post(updatedVariant._links.createOrEditOverride.href, overrideBody)
      .pipe(finalize(() => this._updatingVariant.next(false)));
  }

  /**
   *
   * @param caseId
   * @param updatedFilter: filter to be updated in backend; should be passed in on the list load for filter changes
   * @param showMore: load additional details for each variant (used for report)
   */
  loadVariantList(
    caseId: string,
    options: {
      updatedFilter?: any;
      showMore?: boolean;
      virtualPanelId?: string;
    } = {}
  ) {
    const { updatedFilter, showMore, virtualPanelId } = options;
    this._loadingVariantList.next(true);
    this._variantListError.next('');
    this._currentFilterError.next('');
    this._customBriefErrors.next({});
    return this.loadCurrentFilter(caseId, updatedFilter)
      .pipe(
        mergeMap(() => {
          return this._http
            .get(
              this.authService.getURL('viewCaseResults', { caseId }),
              this.getVariantListRequestOptions(showMore, virtualPanelId)
            )
            .pipe(
              catchError((error) => {
                if (error.status === 304) {
                  return of({});
                }
                throw error;
              })
            );
        }),
        finalize(() => this._loadingVariantList.next(false))
      )
      .subscribe({
        next: (json: any) => {
          // If etag not change, return 304 response
          if (json.status === 200 && this._variantListEtag !== json.headers.get(HEADER_ETAG)) {
            this._variantListEtag = json.headers.get(HEADER_ETAG);
            this._variantListData = json.body;

            /**
             * TODO: Remove this patch once better solution is in place. May be take off `caseNote` from this relation and have it in actual case note response.
             * This patch is to refresh case note/report summary if there is an update available while filter changes.
             */
            if (
              _get(this._revisionData, 'caseNote') !==
              _get(this._variantListData, ['relations', 'caseNote'])
            ) {
              this.loadCaseNote(caseId);
            }

            this._revisionData = this._variantListData.relations || {};
          }

          const variants: AnnotatedVariant[] = [];

          for (const variantData of this._variantListData.variants) {
            const variant = new AnnotatedVariant(variantData);
            variants.push(variant);
          }

          const unprocessedVariants = this._variantListData.unprocessedVariants
            ? this._variantListData.unprocessedVariants.map((unprocessedVariant) =>
                String(unprocessedVariant.value)
              )
            : [];
          this.setSignificantVariantsList(
            variants.filter(
              (variant) =>
                variant.current.report && variant.current.isSignificant && !variant.isOtherBiomarker
            )
          );

          this._otherBiomarkerList.next(variants.filter((variant) => variant.isOtherBiomarker));
          this._unclassifiedVariantList.next(
            variants.filter(
              (variant) =>
                variant.current.report &&
                variant.current.isUnclassified &&
                !variant.isOtherBiomarker
            )
          );
          this._filteredOutVariantList.next(
            variants.filter(
              (variant) =>
                (!variant.current.report || variant.current.isFilteredOut) &&
                !variant.isOtherBiomarker
            )
          );
          this._referenceList.next(this._variantListData.references || []);
          this._currentFilter.next(this._variantListData.filter);
          const biomarkers = this._variantListData.biomarkers
            ? this._variantListData.biomarkers.map(
                (biomarker) => new PertinentNegativeBiomarker(biomarker)
              )
            : [];
          this._biomarkersList.next(biomarkers);
          this.bulkEditHref = _get(this._variantListData, [
            '_links',
            'bulkCreateOrEditVariantOverride',
            'href'
          ]);
          this._containsUnprocessedVariants.next(
            this._variantListData.hasOwnProperty('unprocessedVariants') &&
              this._variantListData.unprocessedVariants !== null
          );
          this._unprocessedVariantList.next(unprocessedVariants);
        },
        error: () => {
          this.clearVariantList();
          this._variantListError.next(`Failed to load variant list of case ${caseId}`);
        }
      });
  }

  private getVariantListRequestOptions(showMore: boolean, virtualPanelId: string) {
    const params = {
      showMore: showMore ? '1' : '0'
    };
    if (virtualPanelId) {
      Object.assign(params, { virtualPanelId });
    }
    const requestOptions = { params: new HttpParams({ fromObject: params }) };
    requestOptions['headers'] = new HttpHeaders().set(HEADER_IF_NONE_MATCH, this._variantListEtag);
    requestOptions['observe'] = 'response';
    return requestOptions;
  }

  get revisionData() {
    return this._revisionData;
  }

  setSignificantVariantsList(significanVariants: AnnotatedVariant[]): void {
    this._significantVariantList.next(significanVariants);
  }

  clearVariantList() {
    this.setSignificantVariantsList([]);
    this._otherBiomarkerList.next([]);
    this._unclassifiedVariantList.next([]);
    this._unprocessedVariantList.next([]);
    this._filteredOutVariantList.next([]);
  }

  loadCaseNote(caseId: string) {
    this._loadingCaseNote.next(true);
    return this._http
      .get(this.authService.getURL('viewCaseNote', { caseId }))
      .pipe(finalize(() => this._loadingCaseNote.next(false)))
      .subscribe({
        next: (json) => {
          this._caseNote.next(json);
          this._caseNoteError.next('');
          this._createOrEditCaseNoteLink = _get(json, '_links.createOrEditCaseNote.href');
        },
        error: () => this._caseNoteError.next('Unable to load.')
      });
  }

  updateCaseNote(caseId: string, noteBody) {
    this._updatingCaseNote.next(true);
    return this._http
      .put(this._createOrEditCaseNoteLink, noteBody)
      .pipe(finalize(() => this._updatingCaseNote.next(false)))
      .subscribe(
        (json) => {
          this._caseNoteError.next('');
          this._caseNote.next(json);
        },
        (error) => {
          if (error.status === 409) {
            this._caseNoteError.next('SAVE_ERR_409');
          } else if (error.status !== 304) {
            this._caseNoteError.next('SAVE_ERR_NOT_304');
          }
        }
      );
  }

  getListToUpdate(annotatedVariant: AnnotatedVariant): ListToUpdateResult {
    const listToUpdate = annotatedVariant.isOtherBiomarker
      ? this._paginatedOtherBiomarkerList
      : this._significantVariantList;
    const clonedList = listToUpdate.value.slice(0);
    const curVariantIndex = clonedList.findIndex((v) => v.id === annotatedVariant.id);
    const curVariant = _clone(clonedList[curVariantIndex]);
    return {
      listToUpdate,
      clonedList,
      curVariantIndex,
      curVariant
    };
  }

  getDisplaySelections(displayColumns: VariantCustomColumn[]): string[] {
    const selectedCols = [];
    displayColumns.forEach((dc) => {
      if (dc.isChecked && !_has(dc, 'subColumns')) selectedCols.push(dc.column);
      if (_has(dc, 'subColumns')) {
        selectedCols.push(...dc.subColumns.filter((c) => c.isChecked).map((c) => c.column));
      }
    });
    return selectedCols;
  }

  updateMetaData(annotatedVariant: AnnotatedVariant, metaDataChange: any) {
    return this._http
      .patch(annotatedVariant._links.createOrEditVariantMetadata.href, metaDataChange)
      .subscribe((json) => {
        const { listToUpdate, clonedList, curVariantIndex, curVariant } =
          this.getListToUpdate(annotatedVariant);
        curVariant.metadata = new VariantMetaData(json);
        clonedList[curVariantIndex] = curVariant;
        listToUpdate.next(clonedList);
        this._variantBriefUpdated.next(curVariant);
      });
  }

  updateVariantBrief(variant: AnnotatedVariant, brief: string, inList?: boolean) {
    this._updatingVariantBrief.next(true);
    const variantId = variant.id;
    const currentBrief = variant.variantBrief;
    const changeShowBrief = brief === '' || (!variant.customizedBrief && brief);
    const newBrief = { text: brief ? brief : null };
    if (currentBrief && currentBrief.revision) {
      newBrief['revision'] = currentBrief.revision;
    }

    return this._http
      .put(variant._links.createOrEditVariantBrief.href, newBrief)
      .pipe(
        mergeMap((json) => {
          const observables = [of(json)];
          if (changeShowBrief) {
            observables.push(
              this._http.patch(variant._links.createOrEditVariantMetadata.href, {
                showBrief: !!brief
              })
            );
          }
          return forkJoin(observables);
        }),
        finalize(() => this._updatingVariantBrief.next(false))
      )
      .subscribe({
        next: ([briefJson, metaData]) => {
          // Update data in the variant list or currently selected variant on success response
          if (inList) {
            const { listToUpdate, clonedList, curVariantIndex, curVariant } =
              this.getListToUpdate(variant);
            curVariant.variantBrief = new VariantBrief(briefJson);
            if (changeShowBrief) {
              curVariant.metadata = new VariantMetaData(metaData);
            }
            clonedList[curVariantIndex] = curVariant;
            listToUpdate.next(clonedList);
            this._variantBriefUpdated.next(curVariant);
          } else {
            const detailedVariant = _clone(this._detailedVariant.value);
            detailedVariant.variantBrief = new VariantBrief(briefJson);
            if (changeShowBrief) {
              detailedVariant.metadata = new VariantMetaData(metaData);
            }
            this._detailedVariant.next(detailedVariant);
          }
        },
        error: (error) => {
          const errors = _clone(this._customBriefErrors.value);
          if (error.status === 409) {
            errors[variantId] = 'SAVE_ERR_409';
          } else if (error.status !== 304) {
            errors[variantId] = 'SAVE_ERR_NOT_304';
          }
          this._customBriefErrors.next(errors);
        }
      });
  }

  updateTherapyExclusions(exclusions: any) {
    return this._http
      .patch(this._detailedVariant.value?._links.createOrEditVariantMetadata.href, exclusions)
      .subscribe({
        next: (json) => {
          this._therapyExclusionsError.next(false);
          this._therapyExclusions.next(new VariantMetaData(json).therapyExclusions);
        },
        error: () => this._therapyExclusionsError.next(true)
      });
  }

  updateBiomarkerMetadata(biomarker: PertinentNegativeBiomarker, metaDataChange: any) {
    return this._http.patch(biomarker._links.editBiomarkerMetadata.href, metaDataChange);
  }

  hasPermissionForVariant(permissionToVerify: string): boolean {
    return _has(this._detailedVariant.value?._links, permissionToVerify);
  }

  hasPermissionForFilter(permissionToVerify: string): boolean {
    return _has(this._currentFilter.value?._links, permissionToVerify);
  }

  hasPermissionForCaseNote(permissionToVerify: string): boolean {
    return _has(this._caseNote.value?._links, permissionToVerify);
  }

  hasPermissionForBulkEdit(): boolean {
    return _has(this._variantListData, ['_links', 'bulkCreateOrEditVariantOverride']);
  }

  hasPermissionForUnprocessedVariants(): boolean {
    return _has(this._variantListData, ['_links', 'viewCaseUnprocessedVariantsSettings']);
  }

  clearVariantListETag() {
    this._variantListEtag = '';
  }

  loadCurrentFilter(caseId: string, updatedFilter?: any) {
    if (updatedFilter) {
      return this.setCurrentFilter(updatedFilter);
    }
    return this._http
      .get(this.authService.getURL('viewVariantFilter', { caseId }), { observe: 'response' })
      .pipe(
        catchError((error) => {
          this._currentFilterError.next('ERR_LOAD_FILTER');
          throw error;
        }),
        mergeMap((res) => {
          return of(res['body']);
        }),
        tap((currentFilters: Revision) => {
          this._revisionData.variantFilter = {
            id: currentFilters.id,
            revision: currentFilters.revision
          };
          this._currentFilter.next(currentFilters);
        })
      );
  }

  setCurrentFilter(filterBody: any) {
    const url = this._currentFilter.value?._links.editVariantFilter.href;

    return this._http.put(url, filterBody).pipe(
      catchError((error) => {
        this._currentFilterError.next('ERR_APPLY_FILTER');
        throw error;
      }),
      tap((currentFilters) => {
        this._currentFilter.next(currentFilters);
      })
    );
  }

  updateEditCaseWarning(updateCaseWarningUrl: string, hideDetails: boolean) {
    return this._http.patch(updateCaseWarningUrl, { hideDetails });
  }

  saveAlias(caseId, variantId, variantAliasData) {
    const url = this.authService.getURL('viewVariantAlias', { caseId, variantId });
    return this._http.put(url, variantAliasData);
  }

  bulkEdit(reqObj: any) {
    this._savingBulkEdit.next(true);
    return this._http
      .post(this.bulkEditHref, reqObj)
      .pipe(finalize(() => this._savingBulkEdit.next(false)));
  }

  loadUnprocessedVariantsSettings(url: string) {
    this._loadingUnprocessedVariantsSettings.next(true);
    return this._http
      .get(url)
      .pipe(finalize(() => this._loadingUnprocessedVariantsSettings.next(false)));
  }

  static linkBriefPMIDs(briefText) {
    let pmidMatches = briefText.match(/\(PMID: \d+\)/g);
    if (pmidMatches) {
      pmidMatches.forEach((matchText) => {
        let pmid = matchText.match(/\d+/)[0];
        let matchLink = `<a href="${PMID_BASE_URL}${pmid}" target="_blank">${matchText}</a>`;
        briefText = briefText.replace(matchText, matchLink);
      });
    }
    return briefText;
  }

  updateShowUnprocessedIndicator(
    updateUnprocessedVariantsSettingHref: string,
    show: boolean
  ): Observable<any> {
    return this._http.put(updateUnprocessedVariantsSettingHref, { showUnprocessedVariants: show });
  }

  // Convert Genomic LOH Value -> Score(which needs 4 maximum decimal and not rounded)
  // example: Value: 0.159999 -> Score: 0.1599
  calculateGenomicLOHScore(num: number) {
    return CommonService.truncateDecimal(num, 4);
  }

  getPaginatedSignificantVariants(
    page: number = 0,
    size: number = 50
  ): Observable<PaginatedVariantData> {
    return this.loadPaginatedVariants(
      PaginatedCaseResponseKeys.VARIANTS,
      new HttpParams()
        .set('tiers', [TierNames.IA, TierNames.IB, TierNames.IIC, TierNames.IID].join(','))
        .set('page', page)
        .set('size', size)
        .set('isOtherBiomarker', false)
        .set('inReport', true)
    ) as Observable<PaginatedVariantData>;
  }

  getPaginatedPertinentNegativeVariants(
    page: number = 0,
    size: number = 50
  ): Observable<PaginatedVariantData> {
    return this.loadPaginatedVariants(
      PaginatedCaseResponseKeys.PERTINENT_NEGATIVES,
      new HttpParams().set('size', size).set('page', page)
    ) as Observable<PaginatedVariantData>;
  }

  getPaginatedFilteredOutVariants(
    page: number = 0,
    size: number = 50
  ): Observable<PaginatedVariantData> {
    return this.loadPaginatedVariants(
      PaginatedCaseResponseKeys.VARIANTS,
      new HttpParams().set('filteredVariants', true).set('page', page).set('size', size)
    ) as Observable<PaginatedVariantData>;
  }

  getPaginatedUnclassifiedVariants(
    page: number = 0,
    size: number = 50
  ): Observable<PaginatedVariantData> {
    return this.loadPaginatedVariants(
      PaginatedCaseResponseKeys.VARIANTS,
      new HttpParams()
        .set('tiers', [TierNames.III, TierNames.UNCLASSIFIED].join(','))
        .set('page', page)
        .set('size', size)
        .set('isOtherBiomarker', false)
        .set('inReport', true)
    ) as Observable<PaginatedVariantData>;
  }

  getPaginatedUnprocessedVariants(
    page: number = 0,
    size: number = 50
  ): Observable<PaginatedVariantData> {
    return this.loadPaginatedVariants(
      PaginatedCaseResponseKeys.UNPROCESSED_VARIANTS,
      new HttpParams().set('page', page).set('size', size)
    ) as Observable<PaginatedVariantData>;
  }

  loadPaginatedVariants(
    linkKey: PaginatedCaseResponseKeys,
    params?: HttpParams
  ): Observable<PaginatedVariantData | PaginatedBiomarkerData> {
    return this._http.get<PaginatedVariantData | PaginatedBiomarkerData>(
      this.caseService.getPaginatedResponseUrl(linkKey),
      {
        params
      }
    );
  }

  getVariantAttributes(variantIds: string[]): void {
    this._http
      .post<{ [key: string]: { variantNames: VariantName[] } }>(
        this.caseService.getPaginatedResponseUrl(PaginatedCaseResponseKeys.ATTRIBUTES),
        {
          variantIds
        }
      )
      .pipe(
        tap((variantNames) => {
          const variantMap: VariantMap = {};
          Object.keys(variantNames).forEach((variantId) => {
            variantMap[variantId] = { name: variantNames[variantId].variantNames };
          });
          this._variantMap.next(variantMap);
        })
      )
      .subscribe();
  }

  getPaginatedReportSummary(params?: HttpParams): Observable<PaginatedReportSummaryData> {
    return this._http.get<PaginatedReportSummaryData>(
      this.caseService.getPaginatedResponseUrl(PaginatedCaseResponseKeys.SUMMARY),
      {
        params
      }
    );
  }

  loadOtherBiomarkers(page: number = 0, size: number = 25): Observable<PaginatedVariantData> {
    this._paginatedOtherBiomarkerList.next([]);
    const params = new HttpParams().set('page', page.toString()).set('size', size.toString());

    return this.loadPaginatedVariants(PaginatedCaseResponseKeys.OTHER_BIOMARKERS, params).pipe(
      tap((response) => {
        const annotatedVariantWithLinks = _get(response, [
          '_embedded',
          'annotatedVariantWithLinks'
        ]);
        const variantData = mapPaginatedDtoToVariantData(annotatedVariantWithLinks);
        const otherBiomarkers = variantData.map((variant) => new AnnotatedVariant(variant));
        this._paginatedOtherBiomarkerList.next(otherBiomarkers);
      })
    ) as Observable<PaginatedVariantData>;
  }

  // TODO ISTN-12834: Make this a dedicated api call so we don't need to call the paginated biomarkers/variants calls: ISTN-12834
  getAllVariantNames(): Observable<string[]> {
    return combineLatest([
      this.loadOtherBiomarkers(0, MAX_PAGINATION_SIZE),
      this.getPaginatedSignificantVariants(0, MAX_PAGINATION_SIZE)
    ]).pipe(
      map(
        ([otherBiomarkersData, significantVariantsData]: [
          PaginatedVariantData,
          PaginatedVariantData
        ]) => {
          const significantVariants: AnnotatedVariant[] =
            significantVariantsData._embedded.annotatedVariantWithLinks
              .map((variantDto) => {
                return new AnnotatedVariant({
                  ...variantDto.annotatedVariantDto,
                  _links: variantDto._links
                });
              })
              .filter((variant) => !variant.isCombination);

          const otherBiomarkers = otherBiomarkersData._embedded.annotatedVariantWithLinks.map(
            (variantDto) => {
              return new AnnotatedVariant({
                ...variantDto.annotatedVariantDto,
                _links: variantDto._links
              });
            }
          );

          return VariantService.getSignificantVariantNames(
            (significantVariants || []).concat(otherBiomarkers || [])
          );
        }
      )
    );
  }
}
